import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import translator from './translator'
import toggler from './toggler'


const config = {
    key: `root`,
    storage,
    whitelist: [ `translator` ],
}

const reducer = combineReducers({
    translator,
    toggler,
})


export default persistReducer(config, reducer)
