export default (size, items, page) => {
    const pager = { }
    pager.page__total = Math.ceil(items.length / size)
    pager.page__first = 1
    pager.page__last = pager.page__total
    pager.page__current = Math.min(Math.max(pager.page__first, page), pager.page__last)
    pager.has__previous = pager.page__current > 1
    pager.has__next = pager.page__current < pager.page__total
    pager.page__previous = pager.page__current - 1
    pager.page__next = pager.page__current + 1
    pager.has__previous__gap = pager.page__first < pager.page__previous
    pager.has__next__gap = pager.page__next < pager.page__last
    pager.to__index = page * size
    pager.from__index = pager.to__index - size
    pager.page = items.filter((_, index) => pager.from__index <= index && index < pager.to__index)
    return pager
}
