import { useSelector, useDispatch } from 'react-redux'

import { portfolio } from 'content'
import { toggle_panel, hide } from 'store/action'


const Portfolio = _ => {
    const dispatch = useDispatch()
    const { language } = useSelector(({ translator }) => translator)
    const toggler = useSelector(({ toggler }) => toggler)

    return (
        <section id="portfolio">
            <h2>{ portfolio.title[ language ] }</h2>
            <div className={ toggler.url === undefined ? `hidden` : `` }>
                <nav>
                    <button className="fas fa-window-close" onClick={ _ => dispatch(hide()) }></button>
                </nav>
                <iframe className="w-full h-full" src={ toggler.url }
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
            </div>
            <div>
                {
                    portfolio.articles.map(article => (
                        <article key={ `portfolio__article__${ article.link }` }>
                            <div>
                                <h3>{ article.title[ language ] }</h3>
                                <p>{ article.summary[ language ]}</p>
                            </div>
                            <div>
                                <img src={ `file/image/gallery/${ article.thumbnail }` } width="450px"/>
                                <div>
                                    <button onClick={ _ => dispatch(toggle_panel(article.link)) }><i className="fab fa-youtube"/></button>
                                </div>
                            </div>
                        </article>
                    ))
                }
            </div>
            <a href="https://www.youtube.com/channel/UC-RcMklLIECvFuuBHgD6k4A/videos">See More</a>
        </section>
    )
}


export default Portfolio
