import { useState, useEffect } from 'react'
import { HashLink } from 'react-router-hash-link'
import { useSelector, useDispatch } from 'react-redux'

import { header } from 'content'
import { select_language, toggle_menu_lang, toggle_menu_nav, hide } from 'store/action'


const screen = 420
const get_name = window => window.innerWidth >= screen ? `Walter von Schnellbacher` : `WS`

const screen__hide = 1024


const Header = _ => {
    const dispatch = useDispatch()
    const [ home, update_home ] = useState(get_name(window))

    const { language } = useSelector(({ translator }) => translator)
    const toggler = useSelector(({ toggler }) => toggler)

    useEffect(_ => {
        // const resize = _ => update_home(get_name(window))
        const resize = _ => {
            if (window.innerWidth >= screen__hide) {
                dispatch(hide())
            }
        }
        window.addEventListener(`resize`, resize)
        return _ => window.removeEventListener(`resize`, resize)
    }, [ ])


    return (
        <>
            <div id="header__alt">
                <nav>
                    {/*<nav>*/}
                    {/*    <button className={ `${ toggler.lang ? `fas fa-window-close` : `fas fa-globe-europe` } hidden` } onClick={ _ => dispatch(toggle_menu_lang()) }/>*/}
                    {/*</nav>*/}
                    <nav>
                        { header.menu.map(item => <HashLink key={ `header__nav__${ item.goto }` } to={ item.goto } onClick={ _ => dispatch(hide()) } smooth>{ item[ language] }</HashLink>) }
                        {/*<HashLink to="#main" smooth>{ home }</HashLink>*/}
                    </nav>
                    <nav>
                        { header.media.map(item => <a key={ `header__media__${ item.link }` } className={ item.icon } href={ item.link } onClick={ _ => dispatch(hide()) }/>) }
                    </nav>
                    <nav>
                        <button className={ toggler.nav ? `fas fa-window-close` : `fas fa-bars` } onClick={ _ => dispatch(toggle_menu_nav()) }/>
                    </nav>
                </nav>
            </div>
            <header id="header">
                <nav>
                    {/*<nav>*/}
                    {/*    <button className={ `${ toggler.lang ? `fas fa-window-close` : `fas fa-globe-europe` } hidden` } onClick={ _ => dispatch(toggle_menu_lang()) }/>*/}
                    {/*</nav>*/}
                    <nav>
                        { header.menu.map(item => <HashLink key={ `header__nav__${ item.goto }` } to={ item.goto } onClick={ _ => dispatch(hide()) } smooth>{ item[ language] }</HashLink>) }
                        {/*<HashLink to="#main" smooth>{ home }</HashLink>*/}
                    </nav>
                    <nav>
                        { header.media.map(item => <a key={ `header__media__${ item.link }` } className={ item.icon } href={ item.link } onClick={ _ => dispatch(hide()) }/>) }
                    </nav>
                    <nav>
                        <button className={ toggler.nav ? `fas fa-window-close` : `fas fa-bars` } onClick={ _ => dispatch(toggle_menu_nav()) }/>
                    </nav>
                </nav>
                <nav className={ toggler.lang ? `` : `hidden` }>
                    { header.languages.map(item => <button key={ `header__lang__${ item.language }` } onClick={ _ => { dispatch(hide()); dispatch(select_language(item.language)) } }>{ item.translation }</button>) }
                </nav>
                <nav className={ toggler.nav ? `` : `hidden` }>
                    { header.menu.map(item => <HashLink key={ `header__nav__${ item.goto }` } to={ item.goto } onClick={ _ => dispatch(hide()) } smooth>{ item[ language] }</HashLink>) }
                    <nav>
                        { header.media.map(item => <a key={ `header__media__${ item.link }` } className={ item.icon } href={ item.link } onClick={ _ => dispatch(hide()) }/>) }
                    </nav>
                </nav>
            </header>
        </>
    )
}


export default Header
