import Home from 'page/home/Home'


const Main = _ => {
    return (
        <>
            <Home/>
        </>
    )
}


export default Main
