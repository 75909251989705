import { HashLink } from 'react-router-hash-link'
import { useSelector } from 'react-redux'

import { repair } from 'content'


const Repair = _ => {
    const { language } = useSelector(({ translator }) => translator)

    return (
        <section id="repair">
            <h2>{ repair.title[ language ] }</h2>
            { repair.paragraphs.map((paragraph, index) => <p key={ `repair__paragraphs__${ index }` }>{ paragraph[ language ] }</p>) }
            <HashLink to="#contact" smooth>Contact</HashLink>
            <div>
                {
                    repair.articles.map((article, index) => (
                        <figure key={ `gallery__figure__${ article.path }` }>
                            <img src={ `/file/image/repair/${ article.path }` } width="450px" alt={ article[ language ] }/>
                            <figcaption>{ article[ language ] }</figcaption>
                        </figure>
                    ))
                }
            </div>

                {/*<p key={ `repair__paragraphs__${ index }` }>{ paragraph[ language ] }</p>) }*/}
        </section>
    )
}


export default Repair
