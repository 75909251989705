import {useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import api from 'utility/api'
import { contact } from 'content'


const Contact = _ => {
    const { language } = useSelector(({ translator }) => translator)

    const [ form, update_form ] = useState({ name: ``, email: ``, message: ``, success: false, errors: [ ] })

    const update_form_input = field => ({ target }) => update_form(current => ({ ...current, [ field ]: target.value }))

    const send = event => {
        event.preventDefault()
        update_form(current => ({ ...current, success: undefined }))
    }

    useEffect(_ => {
        if (form.success === undefined) {
            api.post(`send`, {
                payload: ({ name: form.name, email: form.email, message: form.message }),
                resolve: ({ data }) => {
                    if (data.success) {
                        update_form({name: ``, email: ``, message: ``, success: true, errors: []})
                    } else {
                        update_form(current => ({...current, success: false, errors: data.errors}))
                    }
                }
            })
        }
    }, [ form ])

    return (
        <section id="contact">
            <h2>{ contact.title[ language ] }</h2>
            <form>
                <div>
                    {
                        form.success
                            ? <p>Message sent!</p>
                            : form.success === undefined
                                ? <p>Loading...</p>
                                : (
                                    <ul>
                                        { form.errors.map(({ param, msg }) => <li key={ `contact_'${ param }'_'${ msg }'` }>- { param[ 0 ].toUpperCase() }{ param.slice(1) } { msg.toLowerCase() }</li>) }
                                    </ul>
                                )
                    }
                </div>
                <label htmlFor="name">{ contact.form.name.label[ language ] }</label>
                <input id="name" name="name" type="text" onInput={ update_form_input(`name`) } value={ form.name } placeholder={ contact.form.name.placeholder[ language ] }/>
                <label htmlFor="email">{ contact.form.email.label[ language ] }</label>
                <input id="email" name="email" type="text" onInput={ update_form_input(`email`) } value={ form.email }  placeholder={ contact.form.email.placeholder[ language ] }/>
                <label htmlFor="message">{ contact.form.message.label[ language ] }</label>
                <textarea id="message" name="message" rows={ 7 }  onInput={ update_form_input(`message`) } value={ form.message } placeholder={ contact.form.message.placeholder[ language ] }/>
                <button type="submit" onClick={ send }><i className="fas fa-envelope"/></button>
            </form>
        </section>
    )
}


export default Contact
