import { useSelector } from 'react-redux'

import { title } from 'content'


const Title = _ => {
    const { language } = useSelector(({ translator }) => translator)

    return (
        <header id="title">
            <div>
                <h1>{ title.title[ language ] }</h1>
                <p>{ title.subtitle[ language ] }</p>
            </div>
            <div/>
        </header>
    )
}


export default Title
