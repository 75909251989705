const default_state = { lang: false, nav: false, url: undefined }


export default (state = default_state, action) =>
{
    switch (action.type)
    {
        case `TOGGLER::TOGGLE_MENU_LANG`:
            return { lang: !state.lang, nav: false, url: undefined }
        case `TOGGLER::TOGGLE_MENU_NAV`:
            return { lang: false, nav: !state.nav, url: undefined }
        case `TOGGLER::TOGGLE_PANEL`:
            return { lang: false, nav: false, url: action.value }
        case `TOGGLER::HIDE`:
            return { lang: false, nav: false, url: undefined }
        default:
            return state
    }
}
