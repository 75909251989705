import Title from 'page/home/section/Title'
import About from 'page/home/section/About'
import Portfolio from 'page/home/section/Portfolio'
import Experience from 'page/home/section/Experience'
import Repair from 'page/home/section/Repair'
import Gallery from 'page/home/section/Gallery'
import Contact from 'page/home/section/Contact'


const Home = _ => {
    return (
        <main id="main">
            <Title/>
            <About/>
            <Portfolio/>
            <Experience/>
            <Repair/>
            <Gallery/>
            <Contact/>
        </main>
    )
}


export default Home
