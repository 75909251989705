import translator from './translator'
import toggler from './toggler'


export const select_language = translator.select_language

export const toggle_menu_lang = toggler.toggle_menu_lang
export const toggle_menu_nav = toggler.toggle_menu_nav
export const toggle_panel = toggler.toggle_panel
export const hide = toggler.hide
