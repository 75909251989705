import Header from 'template/Header'
import Main from 'template/Main'
import Footer from 'template/Footer'


function App() {
    return (
        <>
            <Header/>
            <Main/>
            <Footer/>
        </>
    )
}


export default App
