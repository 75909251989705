import { languages } from 'content'

const default_language = `english`
const default_state = { language: default_language }


export default (state = default_state, action) =>
{
    switch (action.type)
    {
        case `TRANSLATOR::SELECT`:
            if (languages.includes(action.value)) {
                return { language: action.value }
            } else if (languages.includes(state.value)) {
                return { language: state.selected }
            } else {
                return { language: default_language }
            }
        default:
            return state
    }
}
