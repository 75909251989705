import { useSelector } from 'react-redux'

import { footer } from 'content'


const Footer = _ => {
    const { language } = useSelector(({ translator }) => translator)

    return (
        <footer id="footer">
            <p>&copy; { footer.copyright[ language ] } <time dateTime="2022">2022</time></p>
        </footer>
    )
}


export default Footer
