import { useState } from 'react'
import { useSelector } from 'react-redux'

import { gallery } from 'content'
import paginate from 'utility/paginator'

const gallery__size = 6

const Gallery = _ => {
    const [ gallery__page, update_gallery__page ] = useState(1)
    const { language } = useSelector(({ translator }) => translator)

    const pager = paginate(gallery__size, gallery.articles.filter(({ include }) => include), gallery__page)

    return (
        <section id="gallery">
            <h2>{ gallery.title[ language ] }</h2>
            <div>
                {
                    pager.page.map(article => (
                        <figure key={ `gallery__figure__${ article.path }` }>
                            <img src={ `/file/image/gallery/${ article.path }` } width="450px" alt={ article[ language ] }/>
                            <figcaption>{ article[ language ] }</figcaption>
                        </figure>
                    ))
                }
            </div>
            <nav>
                {/*{ !pager.has__previous ? <></> : <button onClick={ _ => update_gallery__page(pager.page__first) }><i className="fas fa-chevron-left"/><i className="fas fa-chevron-left"/></button> }*/}
                { !pager.has__previous ? <></> : <button onClick={ _ => update_gallery__page(pager.page__previous) }><i className="fas fa-chevron-left"/></button> }
                { !(pager.page__first < pager.page__previous) ? <></> : <button onClick={ _ => update_gallery__page(pager.page__first) }>{ pager.page__first }</button> }
                { !pager.has__previous__gap ? <></> : <button disabled>..</button> }
                { !pager.has__previous ? <></> : <button onClick={ _ => update_gallery__page(pager.page__previous) }>{ pager.page__previous }</button> }
                <button className="selected" disabled>{ pager.page__current }</button>
                { !pager.has__next ? <></> : <button onClick={ _ => update_gallery__page(pager.page__next) }>{ pager.page__next }</button> }
                { !pager.has__next__gap ? <></> : <button disabled>..</button> }
                { !(pager.page__next < pager.page__last) ? <></> : <button onClick={ _ => update_gallery__page(pager.page__last) }>{ pager.page__last }</button> }
                { !pager.has__next ? <></> : <button onClick={ _ => update_gallery__page(pager.page__next) }><i className="fas fa-chevron-right"/></button> }
                {/*{ !pager.has__next ? <></> : <button onClick={ _ => update_gallery__page(pager.page__last) }><i className="fas fa-chevron-right"/><i className="fas fa-chevron-right"/></button> }*/}
            </nav>
        </section>
    )
}


export default Gallery
