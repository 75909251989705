export const languages = [
    `german`,
    `english`,
    `spanish`,
]


export const header = {
    languages: [
        { language: `german`, code: `de`, translation: `Deutsch`, },
        { language: `english`, code: `en`, translation: `English`, },
        { language: `spanish`, code: `es`, translation: `Español`, },
    ],
    media: [
        { icon: `fab fab fa-youtube-square`, link: `https://www.youtube.com/channel/UC-RcMklLIECvFuuBHgD6k4A`, },
        { icon: `fab fab fa-instagram`, link: `https://www.instagram.com/WSchnellbacher`, },
        { icon: `fab fab fa-twitter-square`, link: `https://www.twitter.com/WSchnellbacher`, },
        { icon: `fab fab fa-linkedin`, link: `https://www.linkedin.com/in/WSchnellbacher`, },
    ],
    menu: [
        {
            goto: `#about`,
            german: `DE`,
            english: `About`,
            spanish: `ES`,
        },
        {
            goto: `#portfolio`,
            german: `DE`,
            english: `Portfolio`,
            spanish: `ES`,
        },
        {
            goto: `#experience`,
            german: `DE`,
            english: `Experience`,
            spanish: `ES`,
        },
        {
            goto: `#repair`,
            german: `DE`,
            english: `Repair`,
            spanish: `ES`,
        },
        {
            goto: `#gallery`,
            german: `DE`,
            english: `Gallery`,
            spanish: `ES`,
        },
        {
            goto: `#contact`,
            german: `DE`,
            english: `Contact`,
            spanish: `ES`,
        },
    ],
}


export const title = {
    title: {
        german: `DE`,
        english: <>Walter von Schnellbacher Schumann</>,
        spanish: `ES`,
    },
    subtitle: {
        german: `DE`,
        english: <>Conductor & Composer</>,
        spanish: `ES`,
    },
}


export const about = {
    title: {
        german: `DE`,
        english: <>About</>,
        spanish: `ES`,
    },
    paragraphs: [
        {
            german: `DE`,
            english: <>Maestro Otto Walter von Schnellbacher Wilmore Ebach Schumann is a British-German conductor and composer born in <a href="https://en.wikipedia.org/wiki/D%C3%BCsseldorf">Düsseldorf, North Rhine-Westphalia, Germany</a>. He studied violin, viola, orchestral conducting, opera - choral conducting and composition in Germany, Italy and Bulgaria.</>,
            spanish: `ES`,
        },
        {
            german: `DE`,
            english: <>Walter von Schnellbacher Schumann has been honoured by the worldwide conductors, Academical societies and Philcharmonic societies with their equivalent of the Order of Merit and makes stands of comparison with legendary conductors such as <a href="https://en.wikipedia.org/wiki/Wilhelm_Furtw%C3%A4ngler">Wilhelm Furtwängler</a>, <a href="https://en.wikipedia.org/wiki/Otto_Klemperer">Otto Klemperer</a>, and <a href="https://en.wikipedia.org/wiki/Yevgeny_Mravinsky">Yevgeny Mravinsky</a> for his high class standing as conductor. He has worked with many leading conductors such as <a href="https://en.wikipedia.org/wiki/Colin_Davis">Sir Colin Davis</a>, <a href="https://en.wikipedia.org/wiki/Neville_Marriner">Sir Neville Marriner</a>, <a href="https://en.wikipedia.org/wiki/Pierre_Boulez">Pierre Boulez</a>, <a href="https://en.wikipedia.org/wiki/Herbert_Blomstedt">Herbert Blomstedt</a>, <a href="https://www.eucoacademy.org/gli-insegnanti-2/jiulius-kalmar/">Julius Kalmar</a>, <a href="https://en.wikipedia.org/wiki/Lior_Shambadal">Lior Shambadal</a>, <a href="http://www.romologessi.com/">Romolo Gessi</a>, <a href="https://it.wikipedia.org/wiki/Giuseppe_Lanzetta">Giuseppe Lanzetta</a>, <a href="https://en.wikipedia.org/wiki/Erwin_Ortner">Erwin Ortner</a> and others.</>,
            spanish: `ES`,
        },
        {
            german: `DE`,
            english: <>Maestro Schnellbacher’s heritage is proven to be with prominent family background (family records and DNA tests); a blood lineage that leads all the way to branch of family <a href="https://en.wikipedia.org/wiki/Johann_Sebastian_Bach">J. S. Bach</a>, <a href="https://en.wikipedia.org/wiki/Robert_Schumann">Robert Schumann</a>,  relatives to an Austrian and German composers and conductors, nobles and writers. Also he is blood related to families such as Schnellbacher, Kühn, Dörr, Stearns/Stern, Frahm, Tripolli, Grider, Fowble, Yoder, Armacost, Wilmore (the famous English/Scottish Anglo-Saxon influential family known to be closely related to the Crown/royal family, Dukes, and members of Corpus Christi), Blattner, Remmele, Nickerson, EBach (Ebaugh/Ybach) etc.</>,
            spanish: `DE`,
        },
        {
            german: `DE`,
            english: <>Maestro Walter von Schnellbacher Schumann becomes the youngest government official after winning a government competition. His work was mainly in the ministry of culture and preservation of culture inheritance. That opened doors for him to work with many government officials and diplomats. Due to his work he visited over 28 countries.</>,
            spanish: `ES`,
        },
        {
            german: `DE`,
            english: <>He currently lives and works in the United States as a conductor, performer and teacher.</>,
            spanish: `ES`,
        },
    ],
}





export const portfolio = {
    title: {
        german: `DE`,
        english: `Portfolio`,
        spanish: `ES`,
    },
    articles: [
        {
            thumbnail: `e7a50931-7525-4434-bf77-6d46a42cf1cf.png`,
            link: `https://www.youtube.com/embed/yj56CaPc6ms`,
            title: {
                german: `DE`,
                english: <>"Le Nozze di Figaro" by Mozart (Part 1), Opera Verona, Italy</>,
                spanish: `ES`,
            },
            summary: {
                german: `DE`,
                english: <></>,
                spanish: `ES`,
            },
        },
        {
            thumbnail: `4916be70-6f9c-491f-b8ce-ca2595d4fdda.png`,
            link: `https://www.youtube.com/embed/VHmMHo3SS7k`,
            title: {
                german: `DE`,
                english: <>"Le Nozze di Figaro" by Mozart (Part 2), Opera Verona, Italy</>,
                spanish: `ES`,
            },
            summary: {
                german: `DE`,
                english: <></>,
                spanish: `ES`,
            },
        },
        {
            thumbnail: `f0ee5ddc-b11a-400f-ac61-1ce7cc224eaf.png`,
            link: `https://www.youtube.com/embed/bxutJ_qfryw`,
            title: {
                german: `DE`,
                english: <>"Vocalise" by Sergei Rachmaninoff, CA, USA</>,
                spanish: `ES`,
            },
            summary: {
                german: `DE`,
                english: <></>,
                spanish: `ES`,
            },
        },
        {
            thumbnail: `6f366a55-6470-4d67-90bf-64d7eb5cdcaa.png`,
            link: `https://www.youtube.com/embed/skPsSpxikOM`,
            title: {
                german: `DE`,
                english: <>"The Holy City" by Stephen Adams, CA, USA</>,
                spanish: `ES`,
            },
            summary: {
                german: `DE`,
                english: <></>,
                spanish: `ES`,
            },
        },
        {
            thumbnail: `ce405495-bcb4-4824-9123-dc7fc5d690c6.png`,
            link: `https://www.youtube.com/embed/uInAJBYt6NM`,
            title: {
                german: `DE`,
                english: <>Simphony 45 by Joseph Haydn (1 & 2 movement Rehearsal), Florence, Italy</>,
                spanish: `ES`,
            },
            summary: {
                german: `DE`,
                english: <></>,
                spanish: `ES`,
            },
        },
        {
            thumbnail: `97b6dc1c-84cb-467f-ab47-4680f9bc600b.png`,
            link: `https://www.youtube.com/embed/eqJ4VUOGePg`,
            title: {
                german: `DE`,
                english: <>Violin Concerto by Wieniawski (2nd movement), CA, USA</>,
                spanish: `ES`,
            },
            summary: {
                german: `DE`,
                english: <></>,
                spanish: `ES`,
            },
        },
        {
            thumbnail: `96874135-f725-447b-9631-d38e1cb2ce3e.png`,
            link: `https://www.youtube.com/embed/GHbG6SFfwo0`,
            // link: `https://www.youtube.com/embed/GHbG6SFfwo0`,
            title: {
                german: `DE`,
                english: `Simphony 1 by G. Bizet (Rehearsal), Florence Symphony Orchestra, Italy`,
                spanish: `ES`,
            },
            summary: {
                german: `DE`,
                english: ``,
                spanish: `ES`,
            },
        },
        {
            thumbnail: `b22d6d0d-1cd4-4114-bdac-45e055c6dd73.png`,
            link: `https://www.youtube.com/embed/E5UC0SOuqa8`,
            title: {
                german: `DE`,
                english: `Piano Concerto by Schumann, BG, EU`,
                spanish: `ES`,
            },
            summary: {
                german: `DE`,
                english: ``,
                spanish: `ES`,
            },
        },
        {
            thumbnail: `6fc78c78-319c-46f9-a138-ddc1994869fe.png`,
            link: `https://www.youtube.com/embed/wg0H5AzC5T8`,
            title: {
                german: `DE`,
                english: `Aria Simon Boccanegra by Verdi, Diplomatic Concert BG, EU`,
                spanish: `ES`,
            },
            summary: {
                german: `DE`,
                english: ``,
                spanish: `ES`,
            },
        },
        {
            thumbnail: `bf13733a-2e32-416d-b326-c0bac00186b9.png`,
            link: `https://www.youtube.com/embed/ABECmUpi4Z8`,
            title: {
                german: `DE`,
                english: `Francisco del Piño Herrera, Florence Symphony Orchestra, Italy`,
                spanish: `ES`,
            },
            summary: {
                german: `DE`,
                english: ``,
                spanish: `ES`,
            },
        },
    ],
}


export const experience = {
    title: {
        german: `DE`,
        english: <>Experience</>,
        spanish: `ES`,
    },
    paragraphs: [
        {
            german: `DE`,
            english: <>* Maestro Schnellbacher holds the traditions of the conducting era through his teachers from the genaration that are leading back in time to <a href="https://en.wikipedia.org/wiki/Arthur_Nikisch">Arthur Nikisch</a>, <a href="https://en.wikipedia.org/wiki/Thomas_Beecham">Sir Thomas Beecham</a>, <a href="https://en.wikipedia.org/wiki/Wilhelm_Furtw%C3%A4ngler">Wilhelm Furtwängler</a>, <a href="https://en.wikipedia.org/wiki/Paul_Hindemith">Paul Hindemith</a>, <a href="https://en.wikipedia.org/wiki/Richard_Strauss">Richard Strauss</a>, <a href="https://en.wikipedia.org/wiki/Arturo_Toscanini">Arturo Toscanini</a>, <a href="https://en.wikipedia.org/wiki/Otto_Klemperer">Otto Klemperer</a>, <a href="https://en.wikipedia.org/wiki/Bruno_Walter">Bruno Walter</a>, <a href="https://en.wikipedia.org/wiki/Kirill_Kondrashin">Kirill Kondrashin</a>, <a href="https://en.wikipedia.org/wiki/Yevgeny_Mravinsky">Yevgeny Mravinsky</a>, <a href="https://en.wikipedia.org/wiki/Ilya_Musin_(conductor)">Ilya Musin</a>, <a href="https://en.wikipedia.org/wiki/Leonard_Bernstein">Leonard Bernstein</a>, <a href="https://en.wikipedia.org/wiki/Herbert_von_Karajan">Herbert von Karajan</a>, <a href="https://en.wikipedia.org/wiki/Hermann_Abendroth">Hermann Abendroth</a>, <a href="https://en.wikipedia.org/wiki/Georges_Pr%C3%AAtre">Georges Prêtre</a>, <a href="https://en.wikipedia.org/wiki/Vassil_Kazandjiev">Vassil Kazandjiev</a>, <a href="https://en.wikipedia.org/wiki/Hermann_Scherchen">Hermann Scherchen</a>, <a href="https://en.wikipedia.org/wiki/Nicolai_Malko">Nicolai Malko</a>, and <a href="https://en.wikipedia.org/wiki/Karl_B%C3%B6hm">Karl Böhm</a>, <a href="https://en.wikipedia.org/wiki/Georg_Solti">Sir Georg Solti</a>, <a href="https://en.wikipedia.org/wiki/Colin_Davis">Sir Colin Davis</a>, and <a href="https://en.wikipedia.org/wiki/Neville_Marriner">Sir Neville Marriner</a>.</>,
            spanish: `ES`,
        },
        {
            german: `DE`,
            english: <>* As a composer Maestro Schnellbacher inherited the traditions through his teachers going all the way back to <a href="https://en.wikipedia.org/wiki/Johannes_Brahms">Johannes Brahms</a>, <a href="https://en.wikipedia.org/wiki/Anton%C3%ADn_Dvo%C5%99%C3%A1k">Antonín Dvořák</a>, <a href="https://en.wikipedia.org/wiki/Paul_Dukas">Paul Dukas</a>, <a href="https://en.wikipedia.org/wiki/Friedrich_Gernsheim">Friedrich Gernsheim</a>, <a href="https://en.wikipedia.org/wiki/Georg_Schumann_(composer)">Georg Schumann</a>, <a href="https://en.wikipedia.org/wiki/Bernhard_Paumgartner">Bernhard Paumgartner</a>, <a href="https://en.wikipedia.org/wiki/Dmitri_Shostakovich">Dimitri Shostakovich</a>, <a href="https://en.wikipedia.org/wiki/Krzysztof_Penderecki">Krzysztof Penderecki</a>, <a href="https://en.wikipedia.org/wiki/Alban_Berg">Alban Berg</a>, <a href="https://en.wikipedia.org/wiki/Pierre_Boulez">Pierre Boulez</a>, <a href="https://en.wikipedia.org/wiki/Arnold_Schoenberg">Arnold Schönberg</a>, etc.</>,
            spanish: `ES`,
        },
        {
            german: `DE`,
            english: <>* Maestro Schnellbacher has an extended experience as a performer and teacher worldwide. His violin and viola teachers keep the tradition of the greatest musicians in the past; a tradition that goes back in time to <a href="https://en.wikipedia.org/wiki/David_Oistrakh">David Oistrakh</a>, <a href="https://en.wikipedia.org/wiki/Leonid_Kogan">Leonid Kogan</a>, <a href="https://en.wikipedia.org/wiki/George_Enescu">George Enescu</a>, <a href="https://en.wikipedia.org/wiki/Serge_Blanc_(violinist)">Serge Blanc</a>, <a href="https://en.wikipedia.org/wiki/Carl_Flesch">Carl Flesch</a>, <a href="https://en.wikipedia.org/wiki/Ernest_Bloch">Ernest Bloch</a>, <a href="https://en.wikipedia.org/wiki/Louis_Spohr">Louis Spohr</a>, <a href="https://en.wikipedia.org/wiki/Jakob_Dont">Jacob Dont</a>, <a href="https://en.wikipedia.org/wiki/Camillo_Sivori">Camillo Sivori</a>, <a href="https://en.wikipedia.org/wiki/Pyotr_Stolyarsky">Pyotr Stolyarsky</a>, <a href="https://en.wikipedia.org/wiki/Joseph_Joachim">Joseph Joachim</a>, <a href="https://en.wikipedia.org/wiki/Apollinaire_de_Kontski">Apollinaire de Kontski</a>, <a href="https://en.wikipedia.org/wiki/Leopold_Auer">Leopold Auer</a>, <a href="https://en.wikipedia.org/wiki/Niccol%C3%B2_Paganini">Niccolò Paganini</a>.</>,
            spanish: `ES`,
        },
        {
            german: `DE`,
            english: <>For more information schedule your first lesson.</>,
            spanish: `ES`,
        },
    ],
    articles: [
        {
            include: true,
            path: `282697021_717172029557145_8478993331126807466_n.jpg`,
            german: `DE`,
            english: <>Maestro Schnellbacher glues and deep cleans the varnish.</>,
            spanish: `ES`,
        },
    ],
}


export const repair = {
    title: {
        german: `DE`,
        english: <>Repair & Tuning</>,
        spanish: `ES`,
    },
    paragraphs: [
        {
            german: `DE`,
            english: <>Maestro Walter von Schnellbacher offers violin, viola and piano repair and piano tuning services. The maestro is professionally trained by prominent German violin makers. He combines the traditions of the greatest violin making schools in Germany, Italy, Czech and Bulgaria. As for piano, he was taught in the traditions of Bösendorfer and Steinway & Sons.</>,
            spanish: `ES`,
        },
        {
            german: `DE`,
            english: <>For more information book a meeting.</>,
            spanish: `ES`,
        },
    ],
    articles: [
        {
            include: true,
            path: `99153634_10223720830834689_2539398155334582272_n.jpg`,
            german: `DE`,
            english: <>Gluing and deep cleaning the varnish.</>,
            spanish: `ES`,
        },
        {
            include: true,
            path: `128830508_10225350862984474_4180063912813594186_n.jpg`,
            german: `DE`,
            english: <>Sanding and fixing the violin fingerboard.</>,
            spanish: `ES`,
        },
        {
            include: true,
            path: `166550310_10226341754156134_4040903783246530918_n.jpg`,
            german: `DE`,
            english: <>Positioning the sound post.</>,
            spanish: `ES`,
        },
        {
            include: true,
            path: `253199122_415621140077104_6446281693548098257_n.jpg`,
            german: `DE`,
            english: <>Repairing the mechanic part.</>,
            spanish: `ES`,
        },
        {
            include: true,
            path: `252983452_292696535900121_5617258312391583345_n.jpg`,
            german: `DE`,
            english: <>Repairing the hammer system.</>,
            spanish: `ES`,
        },
        {
            include: true,
            path: `89547139_230055795056169_5763156150505701376_n.jpg`,
            german: `DE`,
            english: <>Tuning a piano.</>,
            spanish: `ES`,
        },
    ],
}


export const gallery = {
    title: {
        german: `DE Gallery`,
        english: <>Gallery</>,
        spanish: `ES Gallery`,
    },
    articles: [
        {
            include: true,
            path: `4670aad1-d2c5-4e3d-a64e-339c7d23950b.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Maestro Schnellbacher at the San Francisco Symphony Orchestra.</>,
            // english: `Maestro Schnellbacher at the San Francisco Symphony Orchestra, February, 2023`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },
        {
            include: true,
            path: `91987ab6-671a-49f5-8578-4ae6b6d28468.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Maestro Schnellbacher reunited with his mentor and friend <a href="https://en.wikipedia.org/wiki/Herbert_Blomstedt">Maestro Herbert Blomstedt</a> at the San Francisco Symphony Orchestra, Backstage Conducting Room.</>,
            // english: `Maestro Schnellbacher reunited with his friend Maestro Herbert Blomstedt at the San Francisco Symphony Orchestra, Backstage Conducting Room, February, 2023`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },


        {
            include: true,
            path: `a2b36a56-0659-4e3e-bf1b-2838a1e631f2.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Performing "Requiem" by Mozart, SAO Symphony Orchestra and Choir, BG, EU.</>,
            // english: `Performing "Requiem" by Mozart, SAO Symphony Orchestra and Choir, BG, EU, 2009`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },


        {
            include: true,
            path: `8708c416-d233-4cc2-8ecb-30e4a66aa311.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Double Piano Concerto by Felix Mendelssohn, BG, EU.</>,
            // english: `Double Piano Concerto by Felix Mendelssohn, BG, EU, 2009`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },


        {
            include: true,
            path: `18f1172f-297a-4bfe-a699-a354bcf08a02.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Season Concert, BG, EU.</>,
            // english: `Season Concert, BG, EU, 2010`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },


        {
            include: true,
            path: `5a6d951a-8a84-4af4-951d-3145ec21b304.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Diplomatic Gala Concert with the Cyprus Embassy cultural exchange, BG, EU.</>,
            // english: `Diplomatic Gala Concert with the Cyprus Embassy cultural exchange, BG, EU, 2008`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },
        {
            include: true,
            path: `a9c9b654-d138-4beb-96cf-97230da494f5.jpg`,
            name: `Dimplomatic Presidential Gala Concert 2004.jpg`,
            // german: `Gala-Konzert des diplomatischen Präsidenten, Sofia, BG, Europäische Union, 2004.`,
            german: `DE`,
            english: <>Diplomatic Presidential Gala Concert, Sofia, BG, European Union.</>,
            // english: `Diplomatic Presidential Gala Concert, Sofia, BG, European Union, 2004.`,
            spanish: `ES`,
            // spanish: `Presidencial diplomático Concierto de Gala, Sofía, BG, Unión Europea, 2004.`,
        },
        {
            include: true,
            path: `f9c667e7-dec7-4f70-ad63-e282a625930c.jpg`,
            name: `Philcharmonic Chamber Hall Cyprus Embassy Concert 2004-1.jpg`,
            // german: `Philharmonische Kammerhalle, Konzert der zypriotischen Botschaft, Europäische Union, 2004.`,
            german: `DE`,
            english: <>Philharmonic Chamber Hall, Cyprus Embassy Concert, European Union.</>,
            // english: `Philharmonic Chamber Hall, Cyprus Embassy Concert, European Union, 2004.`,
            spanish: `ES`,
            // spanish: `Sala de la Cámara Filarmónica Concierto de la Embajada de Chipre, Unión Europea, 2004.`,
        },


        {
            include: true,
            path: `3df7d157-c85a-48c8-9bb3-ec5e5b01c44c.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Performing seasonal and choir concert, BG, EU</>,
            // english: `Performing seasonal and choir concert, BG, EU, 2006`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },


        {
            include: true,
            path: `0b7263f2-d435-4925-a8ac-a5912c9261b3.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Performing, CA, USA</>,
            // english: `Performing, CA, USA, 2022`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },


        {
            include: true,
            path: `806f2dda-7d9a-43ee-ae3d-5b080603ba39.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>With his mentor <a href="https://en.wikipedia.org/wiki/Neville_Marriner">Maestro Sir Neville Marriner</a> - Sofia Philharmonic, European Union.</>,
            // english: `With Maestro Sir Neville Marriner - Sofia Philharmonic, European Union, 2006.`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },
        {
            include: false,
            path: `4518e6d5-685a-4f5d-a8cc-b70df29b49a4.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>With Maestro Sir Neville Marriner- Sofia Philharmonic, European Union.</>,
            // english: `With Maestro Sir Neville Marriner- Sofia Philharmonic, European Union, 2006.`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },
        {
            include: false,
            path: `47615bf0-87bb-46cf-be3d-498c88d64edd.jpg`,
            name: `Sir Collin David class 2003.jpg`,
            // german: `Sir Colin Davis Klasse, Dresden, Deutschland, Europäische Union, 2003.`,
            german: `DE`,
            english: <>Sir Colin Davis' conducting class in Dresden, Germany, European Union.</>,
            // english: `Sir Colin Davis' conducting class in Dresden, Germany, European Union, 2003.`,
            spanish: `ES`,
            // spanish: `Clase conductora de Sir Colin Davis en Dresden, Alemania, Unión Europea, 2003.`,
        },
        {
            include: true,
            path: `2b1235f7-c112-4f9e-9a80-7d2ae7803d10.jpg`,
            name: `Sir Collin Davis conducting class Dresden 2003.jpg`,
            // german: `Sir Colin Davis dirigiert Klasse, Dresden, Deutschland, Europäische Union, 2003.`,
            german: `DE`,
            english: <>With his teacher <a href="https://en.wikipedia.org/wiki/Colin_Davis">Sir Colin Davis</a> - Dresden, Germany, European Union.</>,
            // english: `Sir Colin Davis' conducting class in Dresden, Germany, European Union, 2003.`,
            spanish: `ES`,
            // spanish: `Clase conductora de Sir Colin Davis en Dresden, Alemania, Unión Europea, 2003.`,
        },
        {
            include: true,
            path: `ccf0f70e-4def-4bb6-8d9b-533cca98cd9c.jpg`,
            name: `Herbert Blomstedt I 2002-2006.jpg`,
            // german: `Maestro Schnellbacher mit Herbert Blomstedt in Leipzig, Europäische Union, 2002-2006.`,
            german: `DE`,
            english: <>With his mentor and friend <a href="https://en.wikipedia.org/wiki/Herbert_Blomstedt">Herbert Blomstedt</a> in Leipzig, Germany, European Union.</>,
            // english: `Maestro Schnellbacher with Herbert Blomstedt in Leipzig, Germany, European Union, 2002-2006.`,
            spanish: `ES`,
            // spanish: `Maestro Schnellbacher con Herbert Blomstedt en Leipzig, Alemania, Unión Europea, 2002-2006.`,
        },
        {
            include: false,
            path: `f904a912-0329-4dd7-a224-344cd832bdd5.jpg`,
            name: `In Gewandhause Orchester Office 2002-2006.jpg`,
            // german: `Im Gewandhausorchester Office, Leipzig, Deutschland, Europäische Union, 2002-2006.`,
            german: `DE`,
            english: <>In Gewandhausorchester Office, Leipzig, Germany, European Union.</>,
            // english: `In Gewandhausorchester Office, Leipzig, Germany, European Union, 2002-2006.`,
            spanish: `ES`,
            // spanish: `En Gewandhausorchester Office, Leipzig, Alemania, Unión Europea, 2002-2006.`,
        },
        {
            include: true,
            path: `6c5b1f65-ae38-4e05-aaca-84962cdc26b6.jpg`,
            name: `with Mo Lior Schambadal, Vicenza, Italy 2010.jpg`,
            // german: `Mit Mo Lior Schambadal, Vicenza, Italien, Europäische Union, 2010.`,
            german: `DE`,
            english: <>With his teacher <a href="https://en.wikipedia.org/wiki/Lior_Shambadal">Mo Lior Schambadal</a>, Vicenza, Italy, European Union.</>,
            // english: `With Mo Lior Schambadal, Vicenza, Italy, European Union, 2010.`,
            spanish: `ES`,
            // spanish: `Con Mo Lior Schambadal, Vicenza, Italia, Unión Europea, 2010.`,
        },
        {
            include: true,
            path: `d66a7847-71cc-4595-9478-5c8451d4d145.jpg`,
            name: `with Maestro Julius Kalmar Arena Di Verona 2009 Italy.jpg`,
            // german: `Mit Maestro Julius Kalmar, Arena Di Verona, Italien, Europäische Union, 2009.`,
            german: `DE`,
            english: <>With his teacher <a href="https://www.eucoacademy.org/gli-insegnanti-2/jiulius-kalmar/">Maestro Julius Kalmar</a>, Arena Di Verona, Italy, European Union.</>,
            // english: `With Maestro Julius Kalmar, Arena Di Verona, Italy, European Union, 2009.`,
            spanish: `ES`,
            // spanish: `Con el Maestro Julius Kalmar Arena Di Verona, Italia, Unión Europea, 2009.`,
        },
        {
            include: true,
            path: `2fe2b4a6-6e4c-405d-8c2d-3c8d0ddac50d.jpg`,
            name: `with Maestro Romolo Gessi Arena Di Verona 2009 Italy.jpg`,
            // german: `Mit Maestro Romolo Gessi, Arena Di Verona, Italien, Europäische Union, 2009.`,
            german: `DE`,
            english: <>With his teacher <a href="http://www.romologessi.com/">Maestro Romolo Gessi</a>, Arena Di Verona, Italy, European Union.</>,
            // english: `With Maestro Romolo Gessi, Arena Di Verona, Italy, European Union, 2009.`,
            spanish: `ES`,
            // spanish: `Con el Maestro Romolo Gessi, Arena Di Verona, Italia, Unión Europea, 2009.`,
        },
        {
            include: false,
            path: `015d3b9b-a271-46e9-b819-a2898cf1a1c5.jpg`,
            name: `Acc Vassil Kazandziev and I in the National Radio Hall 2009.jpg`,
            // german: `Maestro Schnellbacher mit Acc Vassil Kazandziev im NDR, Sofia, BG, Europäische Union, 2009.`,
            german: `DE`,
            english: <>Maestro Schnellbacher with Acc Vassil Kazandjiev in the NDR, Sofia, BG, European Union.</>,
            // english: `Maestro Schnellbacher with Acc Vassil Kazandjiev in the NDR, Sofia, BG, European Union, 2009.`,
            spanish: `ES`,
            // spanish: `Maestro Schnellbacher con Acc Vassil Kazandjiev en el NDR, Sofía, BG, Unión Europea, 2009.`,
        },
        {
            include: true,
            path: `05d74342-3abf-4426-a5b4-a0744e89a33d.jpg`,
            name: `Acc Vassil Kazandziev and I in the National Radio Hall 2009 - 1.jpg`,
            // german: `Maestro Schnellbacher mit Acc Vassil Kazandjiev im NDR, Sofia, BG, Europäische Union, 2009.`,
            german: `DE`,
            english: <>With his teacher <a href="https://en.wikipedia.org/wiki/Vassil_Kazandjiev">Acc Vassil Kazandjiev</a> in the National D Radio concert hall, Sofia, BG, European Union.</>,
            // english: `Maestro Schnellbacher with Acc Vassil Kazandzjiev in the National D Radio concert hall, Sofia, BG, European Union, 2009.`,
            spanish: `ES`,
            // spanish: `Maestro Schnellbacher con Acc Vassil Kazandjiev en el NDR, Sofía, BG, Unión Europea, 2009.`,
        },
        {
            include: true,
            path: `8ccd9e7a-29ed-4628-ad13-8b8f6c9a2ceb.jpg`,
            name: `With Mo Giuseppe Lanzzetta in Florence, Italy 2013.jpg`,
            // german: `Mit Mo Giuseppe Lanzetta in Florenz, Italien, Europäische Union, 2013.`,
            german: `DE`,
            english: <>With his teacher <a href="https://it.wikipedia.org/wiki/Giuseppe_Lanzetta">Mo Giuseppe Lanzetta</a> in Florence, Italy, European Union.</>,
            // english: `With Mo Giuseppe Lanzetta in Florence, Italy, European Union, 2013.`,
            spanish: `ES`,
            // spanish: `Con Mo Giuseppe Lanzetta en Florencia, Italia, Unión Europea, 2013.`,
        },
        {
            include: false,
            path: `38772335-3ba6-4c3c-ae1d-635a9b851405.jpg`,
            name: `With Mo Giuseppe Lanzzetta in Florence, Italy 2013 - 1.jpg`,
            // german: `Mit Mo Giuseppe Lanzetta in Florenz, Italien, Europäische Union, 2013.`,
            german: `DE`,
            english: <>With Mo Giuseppe Lanzetta in Florence, Italy, European Union.</>,
            // english: `With Mo Giuseppe Lanzetta in Florence, Italy, European Union, 2013.`,
            spanish: `ES`,
            // spanish: `Con Mo Giuseppe Lanzetta en Florencia, Italia, Unión Europea, 2013.`,
        },
        {
            include: false,
            path: `73fac9ad-3763-41b4-aae8-b7064c7410fd.jpg`,
            name: `Maestro Andrea Portera and Maestro Walter von Ganttemberg, September 2013.jpg`,
            // german: `Maestro Andrea Portera und Maestro Schnellbacher, September 2013.`,
            german: `DE`,
            english: <>Maestro Andrea Portera and Maestro Schnellbacher.</>,
            // english: `Maestro Andrea Portera and Maestro Schnellbacher, September 2013.`,
            spanish: `ES`,
            // spanish: `Maestro Andrea Portera y Maestro Schnellbacher, septiembre de 2013.`,
        },
        {
            include: true,
            path: `1539c948-269e-4f07-b65f-e30613342316.jpg`,
            name: `Maestro Andrea Portera and Maestro Walter von Ganttemberg, September 2013 - 1.jpg`,
            // german: `Maestro Andrea Portera und Maestro Schnellbacher, September 2013.`,
            german: `DE`,
            english: <>Maestro Andrea Portera and Maestro Schnellbacher.</>,
            // english: `Maestro Andrea Portera and Maestro Schnellbacher, September 2013.`,
            spanish: `ES`,
            // spanish: `Maestro Andrea Portera y Maestro Schnellbacher, septiembre de 2013.`,
        },
        {
            include: true,
            path: `13cc7a9a-f9ab-4e7a-aca2-09d17b45912a.jpg`,
            name: `Maestro Andrea Portera and Maestro Walter von Ganttemberg, September 2013 - 1.jpg`,
            // german: `Maestro Andrea Portera und Maestro Schnellbacher, September 2013.`,
            german: `DE`,
            english: <>With Maestro Schnellbacher's friend and renowned US choir conductor John Ratledge, Madrid, Spain.</>,
            // english: `With Maestro Schnellbacher's friend and renowned US choir conductor John Ratledge, Madrid, Spain, 2012`,
            spanish: `ES`,
            // spanish: `Maestro Andrea Portera y Maestro Schnellbacher, septiembre de 2013.`,
        },
        {
            include: false,
            path: `a829ba98-8edb-458f-919c-d17e1cd5dca6.jpg`,
            name: `Former President of State of Bulgaria, EU, 2004 George Parvanov.jpg`,
            // german: `Maestro Schnellbacher dirigiert ein diplomatisches Gallakonzert in Anwesenheit des ehemaligen bulgarischen Staatspräsidenten der Europäischen Union, Georgi Parvanov, 2004.`,
            german: `DE`,
            english: <>Maestro Schnellbacher conducts diplomatic galla concert in the presence of the Former President of State of Bulgaria, European Union, Georgi Parvanov.</>,
            // english: `Maestro Schnellbacher conducts diplomatic galla concert in the presence of the Former President of State of Bulgaria, European Union, Georgi Parvanov, 2004.`,
            spanish: `ES`,
            // spanish: `El maestro Schnellbacher dirige un concierto de gala diplomática en presencia del ex presidente de Estado de Bulgaria, Unión Europea, Georgi Parvanov, 2004.`,
        },
        {
            include: false,
            path: `1f5b2e2c-bdac-4df5-b95f-abc37e9e21a4.jpg`,
            name: `Former PRESIDENT of State of Bulgaria, EU Peter Stoyanoff and I, 2000.jpg`,
            // german: `Maestro Schnellbacher als Regierungsbeamter beim ehemaligen Präsidenten des Staates Bulgarien, Europäische Union, Peter Stoyanoff, 2000.`,
            german: `DE`,
            english: <>Maestro Schnellbacher as a Government Official with the Former PRESIDENT of State of Bulgaria, European Union, Peter Stoyanoff.</>,
            // english: `Maestro Schnellbacher as a Government Official with the Former PRESIDENT of State of Bulgaria, European Union, Peter Stoyanoff, 2000.`,
            spanish: `ES`,
            // spanish: `Maestro Schnellbacher como Funcionario del Gobierno con el ex PRESIDENTE de Estado de Bulgaria, Unión Europea, Peter Stoyanoff, 2000.`,
        },

        {
            include: true,
            path: `7c80a18e-ab44-49ad-b3c2-d76e1ca5fcc5.jpg`,
            name: `with Vjacheslav Valeev at the International  Conducting Competition - Genady Rozdestvensskiy - Sofia.jpg`,
            // german: `mit Vjacheslav Valeev beim Internationalen Dirigentenwettbewerb - Genady Rozdestvensskiy - Sofia, BG, Europäische Union, 2006.`,
            german: `DE`,
            english: <>with Vjacheslav Valeev at the International  Conducting Competition - Genady Rozdestvensskiy - Sofia, BG, European Union.</>,
            // english: `with Vjacheslav Valeev at the International  Conducting Competition - Genady Rozdestvensskiy - Sofia, BG, European Union, 2006.`,
            spanish: `ES`,
            // spanish: `con Vjacheslav Valeev en el Concurso Internacional de Dirección - Gennady Rozhdestvensky - Sofía, BG, Unión Europea, 2006.`,
        },
        {
            include: false,
            path: `2cd2a287-ce12-4a91-9ce4-19198ba77117.jpg`,
            name: `with Vjacheslav Valeev at the International  Conducting Competition - Genady Rozdestvensskiy- 2006 Sofia.jpg`,
            // german: `Mit Vjacheslav Valeev beim Internationalen Dirigentenwettbewerb - Genady Rozdestvensskiy - Sofia, BG, Europäische Union, 2006.`,
            german: `DE`,
            english: <>With Vjacheslav Valeev at the International  Conducting Competition - Genady Rozdestvensskiy - Sofia, BG, European Union.</>,
            // english: `With Vjacheslav Valeev at the International  Conducting Competition - Genady Rozdestvensskiy - Sofia, BG, European Union, 2006.`,
            spanish: `ES`,
            // spanish: `con Vjacheslav Valeev en el Concurso Internacional de Dirección - Gennady Rozhdestvensky - Sofía, BG, Unión Europea, 2006.`,
        },

        {
            include: true,
            path: `11071c95-402f-482a-b3aa-4081945c9966.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Appointed Concert Master at a Christmas concert, CA, USA.</>,
            // english: `Appointed Concert Master at a Christmas concert, CA, USA, 2021`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },


        {
            include: true,
            path: `af365608-20ff-4b84-91f1-7ee7e393b87d.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>100th years anniversary of the Legendary Bass Boris Christoff concert, BG, EU.</>,
            // english: `100th years anniversary of the Legendary Bass Boris Christoff concert, BG, EU, 2014`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },


        {
            include: true,
            path: `1942aca0-d229-4afe-a280-108db40f3025.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Appointed as a conductor and music expert on the World Conference, Serbia, Europe</>,
            // english: `Appointed as a conductor and music expert on the World Conference, Serbia, Europe, 2013`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },


        {
            include: true,
            path: `7870f70f-4b40-4679-8dfe-57361d4d7393.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Appointed as a conductor and music expert on the World Conference, Serbia, Europe</>,
            // english: `Appointed as a conductor and music expert on the World Conference, Serbia, Europe, 2013`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },


        {
            include: true,
            path: `5313d646-5a40-451a-8694-4cdb3ee08a7d.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Orchestra player in the Valencia Symphony Orchestra, Spain, EU.</>,
            // english: `Orchestra player in the Valencia Symphony Orchestra, Spain, EU, 2012`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },


        {
            include: true,
            path: `60ba8d7f-b754-43db-adc3-222ec02f4967.jpg`,
            name: `with Maestro Sir Nevil Mariner- Sofia Philharmonic 2006-1.jpg`,
            // german: `Mit Maestro Sir Neville Marriner - Sofia Philharmonic, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Orchestra player in the Valencia Symphony Orchestra, Spain, EU.</>,
            // english: `Orchestra player in the Valencia Symphony Orchestra, Spain, EU, 2012`,
            spanish: `ES`,
            // spanish: `Con el Maestro Sir Neville Marriner- Sofia Philharmonic, Unión Europea, 2006.`,
        },

        {
            include: false,
            path: `2bd022bc-2515-439c-b668-8673c25f9a1f.jpg`,
            name: `Maestro with Soloist Enrico Balboni - Concert-Master of Opera Verona, Vicenza, Italy, 2009.jpg`,
            // german: `Aufführung des Mendelssohn-Konzerts für Violine und Orchester mit dem Solisten Enrico Balboni - Konzertmeister der Oper Verona, Vicenza, Italien, Europäische Union, 2009.`,
            german: `DE`,
            english: <>Performing Mendelssohn Concerto for Violin and orchestra with Soloist Enrico Balboni - Concert-Master of Opera Verona, Vicenza, Italy, European Union.</>,
            // english: `Performing Mendelssohn Concerto for Violin and orchestra with Soloist Enrico Balboni - Concert-Master of Opera Verona, Vicenza, Italy, European Union, 2009.`,
            spanish: `ES`,
            // spanish: `Concierto de Mendelssohn para violín y orquesta con el solista Enrico Balboni - Concertmaster de Opera Verona, Vicenza, Italia, Unión Europea, 2009.`,
        },
        {
            include: false,
            path: `95f7d538-be15-4968-9bdf-6af650633824.jpg`,
            name: `Maestro with Soloist Enrico Balboni - Concert-Master of Opera Verona, Vicenza, Italy, 2009 - 1.jpg`,
            // german: `Aufführung des Mendelssohn-Konzerts für Violine und Orchester mit dem Solisten Enrico Balboni - Konzertmeister der Oper Verona, Vicenza, Italien, Europäische Union, 2009.`,
            german: `DE`,
            english: <>Performing Mendelssohn Concerto for Violin and orchestra with Soloist Enrico Balboni - Concert-Master of Opera Verona, Vicenza, Italy, European Union.</>,
            // english: `Performing Mendelssohn Concerto for Violin and orchestra with Soloist Enrico Balboni - Concert-Master of Opera Verona, Vicenza, Italy, European Union, 2009.`,
            spanish: `ES`,
            // spanish: `Concierto de Mendelssohn para violín y orquesta con el solista Enrico Balboni - Concertmaster de Opera Verona, Vicenza, Italia, Unión Europea, 2009.`,
        },
        {
            include: true,
            path: `7cf89c70-5072-4701-b366-b8f633009030.jpg`,
            name: `Maestro with Soloist Enrico Balboni - Concert-Master of Opera Verona, Vicenza, Italy, 2009 - 2.jpg`,
            // german: `Aufführung des Mendelssohn-Konzerts für Violine und Orchester mit dem Solisten Enrico Balboni - Konzertmeister der Oper Verona, Vicenza, Italien, Europäische Union, 2009.`,
            german: `DE`,
            english: <>Performing Mendelssohn Concerto for Violin and orchestra with Soloist Enrico Balboni - Concert-Master of Opera Verona, Vicenza, Italy, European Union.</>,
            // english: `Performing Mendelssohn Concerto for Violin and orchestra with Soloist Enrico Balboni - Concert-Master of Opera Verona, Vicenza, Italy, European Union, 2009.`,
            spanish: `ES`,
            // spanish: `Concierto de Mendelssohn para violín y orquesta con el solista Enrico Balboni - Concertmaster de Opera Verona, Vicenza, Italia, Unión Europea, 2009.`,
        },
        {
            include: false,
            path: `fdf4f787-3784-4a00-bdfa-1401d857d8cb.jpg`,
            name: `Maestro with Soloist Enrico Balboni - Concert-Master of Opera Verona, Vicenza, Italy, 2009 - 3.jpg`,
            // german: `Aufführung des Mendelssohn-Konzerts für Violine und Orchester mit dem Solisten Enrico Balboni - Konzertmeister der Oper Verona, Vicenza, Italien, Europäische Union, 2009`,
            german: `DE`,
            english: <>Performing Mendelssohn Concerto for Violin and orchestra with Soloist Enrico Balboni - Concert-Master of Opera Verona, Vicenza, Italy, European Union</>,
            // english: `Performing Mendelssohn Concerto for Violin and orchestra with Soloist Enrico Balboni - Concert-Master of Opera Verona, Vicenza, Italy, European Union, 2009`,
            spanish: `ES`,
            // spanish: `Concierto de Mendelssohn para violín y orquesta con el solista Enrico Balboni - Concertmaster de Opera Verona, Vicenza, Italia, Unión Europea, 2009.`,

        },

        {
            include: false,
            path: `8c007e15-2a49-4044-b0f7-124b570ac36c.jpg`,
            name: `Florence Sym Orchestra, Florence, Italy 2013.jpg`,
            // german: `Florence Symphony Orchestra, Florenz, Italien, Europäische Union, 2013.`,
            german: `DE`,
            english: <>Florence Symphony Orchestra, Florence, Italy, European Union.</>,
            // english: `Florence Symphony Orchestra, Florence, Italy, European Union, 2013.`,
            spanish: `ES`,
            // spanish: `Orquesta Sinfónica de Florencia, Florencia, Italia, Unión Europea, 2013.`,
        },
        {
            include: true,
            path: `9d8ad083-3a0d-43c9-84a5-4429ce75fd8a.jpg`,
            name: `Florence Symphony Orchestra, Florence, Italy  2013.jpg`,
            // german: `Florence Symphony Orchestra, Florenz, Italien, Europäische Union, 2013.`,
            german: `DE`,
            english: <>Florence Symphony Orchestra, Florence, Italy, European Union.</>,
            // english: `Florence Symphony Orchestra, Florence, Italy, European Union, 2013.`,
            spanish: `ES`,
            // spanish: `Orquesta Sinfónica de Florencia, Florencia, Italia, Unión Europea, 2013.`,
        },
        {
            include: true,
            path: `05da4d4c-893f-459e-bcbf-adfd33ed48ee.jpg`,
            name: `Maestro Schnellbacher with the leading Viola player of The Fiorentina Philharmonic Orchestra, Florence, Italy, 2013.jpg`,
            // german: `Maestro Schnellbacher mit dem führenden Bratschisten der Fiorentina Philharmonic Orchestra, Florenz, Italien, Europäische Union, 2013.`,
            german: `DE`,
            english: <>Maestro Schnellbacher with the leading Viola player of The Fiorentina Philharmonic Orchestra, Florence, Italy, European Union.</>,
            // english: `Maestro Schnellbacher with the leading Viola player of The Fiorentina Philharmonic Orchestra, Florence, Italy, European Union, 2013.`,
            spanish: `ES`,
            // spanish: `Maestro Schnellbacher con el principal jugador de Viola de la Orquesta Filarmónica de la Fiorentina, Florencia, Italia, Unión Europea, 2013`,
        },
        {
            include: false,
            path: `a9eeaf50-0384-455c-8ad7-2ebf64459c38.jpg`,
            name: `Florence 2013.jpg`,
            // german: `Der Komponist Francisco del Pino Herrera, Schüler der Florence Composition Factory, spricht nach der Probe auf Einladung des Maestro über sein Stück mit dem Dirigenten Walter Schnellbacher. Der Maestro gibt Empfehlungen für eine bessere Interpretation des Stücks - in der Villa Castiglione Impruneta, Florenz, Italien, Europäische Union, September 2013.`,
            german: `DE`,
            english: <>The composer Francisco del Pino Herrera, student of Florence Composition Factory, speaking of his piece with the conductor Walter Schnellbacher after rehearsal at the invitation of the maestro. The Maestro provides recommendations for better interpretation of the piece — at Villa Castiglione Impruneta, Florence, Italy, European Union.</>,
            // english: `The composer Francisco del Pino Herrera, student of Florence Composition Factory, speaking of his piece with the conductor Walter Schnellbacher after rehearsal at the invitation of the maestro. The Maestro provides recommendations for better interpretation of the piece — at Villa Castiglione Impruneta, Florence, Italy, European Union, September, 2013.`,
            spanish: `ES`,
            // spanish: `El compositor Francisco del Pino Herrera, estudiante de la Fábrica de Composición de Florencia, habla de su pieza con el director de orquesta Walter Schnellbacher después del ensayo por invitación del maestro. El Maestro ofrece recomendaciones para una mejor interpretación de la pieza: en Villa Castiglione Impruneta, Florencia, Italia, Unión Europea, septiembre de 2013.`,
        },
        {
            include: false,
            path: `2de63bc2-1a38-46e3-81a9-a568b89f3307.jpg`,
            name: `1997.jpg`,
            // german: `Violla spielen im Kammerquartett, Sofia, BG, Europäische Union, 1995.`,
            german: `DE`,
            english: <>Playing viola in chamber quartet, Sofia, BG, European Union.</>,
            // english: `Playing viola in chamber quartet, Sofia, BG, European Union, 1995.`,
            spanish: `ES`,
            // spanish: `Tocando la viola en el cuarteto de cámara, Sofía, BG, Unión Europea, 1995.`,
        },
        {
            include: false,
            path: `af3c95fc-1423-407a-a70a-85416335b0c0.jpg`,
            name: `academic Orchestra 2001-2006.jpg`,
            // german: `Akademisches Sinfonieorchester, das Maestro Schnellbacher im Zeitraum 2001-2007 dirigierte, Sofia, BG, Europäische Union.`,
            german: `DE`,
            english: <>Academy Symphony Orchestra, Sofia, BG, European Union.</>,
            // english: `Academy Symphony Orchestra that Maestro Schnellbacher conducted in the period  2001-2007, Sofia, BG, European Union.`,
            spanish: `ES`,
            // spanish: `Orquesta Sinfónica Académica que el Maestro Schnellbacher dirigió en el período 2001-2007, Sofía, BG, Unión Europea.`,
        },
        {
            include: true,
            path: `eb564289-625a-495d-a29c-7811fb6084de.jpg`,
            name: `American University Choir Concert 2005.jpg`,
            // german: `Amerikanisches Universitätschorkonzert, Europäische Union, 2005.`,
            german: `DE`,
            english: <>Choral Concert at the American University, European Union.</>,
            // english: `Choral Concert at the American University, European Union, 2005.`,
            spanish: `ES`,
            // spanish: `Concierto coral en la Universidad Americana, Unión Europea, 2005.`,
        },
        {
            include: false,
            path: `8a65940a-901b-4086-aa88-4c979db059ad.jpg`,
            name: `Blagoevgrad Chamber Orchestra 2006-2008-1.jpg`,
            // german: `Kammerorchester Blagoevgrad, Europäische Union, 2006-2008.`,
            german: `DE`,
            english: <>Blagoevgrad Chamber Orchestra, European Union.</>,
            // english: `Blagoevgrad Chamber Orchestra, European Union, 2006-2008.`,
            spanish: `ES`,
            // spanish: `Orquesta de Cámara de Blagoevgrad, Unión Europea, 2006-2008.`,
        },
        {
            include: true,
            path: `3c7e52ca-fa6b-4fff-a6b2-9873db53b752.jpg`,
            name: `Blagoevgrad Chamber Orchestra 2006-2008.jpg`,
            // german: `Kammerorchester Blagoevgrad, Europäische Union 2006-2008.`,
            german: `DE`,
            english: <>Blagoevgrad Chamber Orchestra, European Union.</>,
            // english: `Blagoevgrad Chamber Orchestra, European Union 2006-2008.`,
            spanish: `ES`,
            // spanish: `Orquesta de Cámara de Blagoevgrad, Unión Europea, 2006-2008.`,
        },
        {
            include: true,
            path: `0fbd1125-c07c-4c67-bf61-027934e55c13.jpg`,
            name: `Choral Concert 2005-1.jpg`,
            // german: `Chorkonzert an der Nationalen Musikakademie in Sofia, Europäische Union 2005.`,
            german: `DE`,
            english: <>Choral Concert at the National Music Academy in Sofia, European Union.</>,
            // english: `Choral Concert at the National Music Academy in Sofia, European Union 2005.`,
            spanish: `ES`,
            // spanish: `Concierto coral en la Academia Nacional de Música de Sofía, Unión Europea 2005.`,

        },
        {
            include: false,
            path: `2775fb83-288b-491c-b5f8-86ee9070bc0a.jpg`,
            name: `Choral Concert 2005.jpg`,
            // german: `Chorkonzert an der Nationalen Musikakademie in Sofia, Europäische Union 2005.`,
            german: `DE`,
            english: <>Choral Concert at the National Music Academy in Sofia, European Union.</>,
            // english: `Choral Concert at the National Music Academy in Sofia, European Union 2005.`,
            spanish: `ES`,
            // spanish: `Concierto coral en la Academia Nacional de Música de Sofía, Unión Europea 2005.`,
        },
        {
            include: false,
            path: `976b949e-6f35-4fda-91bd-8d1538456dc8.jpg`,
            name: `open Concert 2009 City of Garda, Italy Philcharmonia Veneta.jpg`,
            // german: `Offenes Konzert mit Philharmonia Veneto, Stadt Garda, Italien, Europäische Union, 2009.`,
            german: `DE`,
            english: <>Open Concert with Philharmonia Veneto, City of Garda, Italy, European Union.</>,
            // english: `Open Concert with Philharmonia Veneto, City of Garda, Italy, European Union, 2009.`,
            spanish: `ES`,
            // spanish: `Concierto abierto con Philharmonia Veneto, Ciudad de Garda, Italia, Unión Europea, 2009.`,
        },
        {
            include: false,
            path: `124e3de6-2369-4cf5-ac49-0c836f597d22.jpg`,
            name: `open Concert 2009 City of Garda, Italy Philcharmonia Veneta1.jpg`,
            // german: `Offenes Konzert mit Philharmonia Veneto, Stadt Garda, Italien, Europäische Union, 2009.`,
            german: `DE`,
            english: <>Open Concert with Philharmonia Veneto, City of Garda, Italy, European Union.</>,
            // english: `Open Concert with Philharmonia Veneto, City of Garda, Italy, European Union, 2009.`,
            spanish: `ES`,
            // spanish: `Concierto abierto con Philharmonia Veneto, Ciudad de Garda, Italia, Unión Europea, 2009.`,
        },
        {
            include: false,
            path: `9337d356-14a2-4e6c-96ea-d60e16d774c8.jpg`,
            name: `Philcharmonic Chamber Hall Cyprus Embassy Concert 2004.jpg`,
            // german: `Philharmonische Kammerhalle, Konzert der zypriotischen Botschaft, Europäische Union, 2004.`,
            german: `DE`,
            english: <>Philharmonic Chamber Hall, Cyprus Embassy Concert, European Union.</>,
            // english: `Philharmonic Chamber Hall, Cyprus Embassy Concert, European Union, 2004.`,
            spanish: `ES`,
            // spanish: `Sala de la Cámara Filarmónica Concierto de la Embajada de Chipre, Unión Europea, 2004.`,
        },
        {
            include: false,
            path: `6b9aaa2c-b84d-497b-8e9f-bcf709c2f397.jpg`,
            name: `playing in a gig 1998.jpg`,
            // german: `Bei einem Auftritt spielen, Europäische Union, 1998.`,
            german: `DE`,
            english: <>Playing in a gig, European Union.</>,
            // english: `Playing in a gig, European Union, 1998.`,
            spanish: `ES`,
            // spanish: `Tocando en un concierto, Unión Europea, 1998.`,
        },
        {
            include: false,
            path: `6170aa5d-35ac-4630-8bc7-7e92a2e88214.jpg`,
            name: `Rousse Philcharmoy 2008.jpg`,
            // german: `Rousse philcharmonischen orchester, BG, Europäische Union, 2008.`,
            german: `DE`,
            english: <>Rousse Philcharmonic, BG, European Union.</>,
            // english: `Rousse Philcharmonic, BG, European Union, 2008.`,
            spanish: `ES`,
            // spanish: `Filarmónica de Rousse, BG, Unión Europea, 2008.`,
        },
        {
            include: true,
            path: `8ffce5df-050d-400e-878f-025bc1172072.jpg`,
            name: `Sofia Academic Orchestra 2006.jpg`,
            // german: `Sofia Academic Orchestra, BG, Europäische Union, 2006.`,
            german: `DE`,
            english: <>Sofia Academy Orchestra, BG, European Union.</>,
            // english: `Sofia Academy Orchestra, BG, European Union, 2006.`,
            spanish: `ES`,
            // spanish: `Orquesta Académica de Sofía, BG, Unión Europea, 2006.`,
        },
        {
            include: true,
            path: `967ac05b-d819-46f4-819d-d9cdaa144604.jpg`,
            name: `conducting Philharmonia Veneta, Italy 2010.jpg`,
            // german: `Dirigieren der Philharmonie Veneta, Italien, Europäische Union, 2010.`,
            german: `DE`,
            english: <>Conducting Philharmonic Veneta, Italy, European Union.</>,
            // english: `Conducting Philharmonic Veneta, Italy, European Union, 2010.`,
            spanish: `ES`,
            // spanish: `Conducir Philharmonia Veneta, Italia, Unión Europea, 2010`,
        },
        {
            include: false,
            path: `f43ee457-8405-4591-ae60-98d3fc1578b6.jpg`,
            name: `Mama Mary and I - 2008 Rousse, BG, EU.jpg`,
            // german: `Maestro Schnellbacher mit Mama Mary, Rousse, BG, Europäische Union, 2008. (Mama Mary ist die Frau, die dem Maestro das Leben gerettet hat und er verdankt ihr all seinen zukünftigen Erfolg)`,
            german: `DE`,
            english: <>Maestro Schnellbacher with Mama Mary, Rousse, BG, European Union. (Mama Mary is the woman who saved the Maestro's life and he owes all of his future success to her)</>,
            // english: `Maestro Schnellbacher with Mama Mary, Rousse, BG, European Union, 2008. (Mama Mary is the woman who saved the Maestro's life and he owes all of his future success to her)`,
            spanish: `ES`,
            // spanish: `Con Mama Mary, la mujer que salvó la vida del Maestro Schnellbacher y el Maestro le debe todo su éxito futuro después. Rousse, BG, Unión Europea, 2008.`,
        },
        {
            include: false,
            path: `ea7b0ceb-2b63-45ef-94cb-0be035daec7e.jpg`,
            name: `Mama Mary and I - 2008 Rousse, BG, EU .jpg`,
            // german: `Maestro Schnellbacher mit Mama Mary, Rousse, BG, Europäische Union, 2008.`,
            german: `DE`,
            english: <>Maestro Schnellbacher with Mama Mary, Rousse, BG, European Union.</>,
            // english: `Maestro Schnellbacher with Mama Mary, Rousse, BG, European Union, 2008.`,
            spanish: `ES`,
            // spanish: `Con Mama Mary, Rousse, BG, Unión Europea, 2008.`,

        },
        {
            include: false,
            path: `b148404b-e3aa-4ccc-9d4e-613035408bf6.jpg`,
            name: `Anastasia and Naiden Gantscheff.jpg`,
            // german: `Naiden Gantscheff (Gantchev) / 1918 - 1987 / ist ein österreichisch-slawischer Geigenbauer, der hauptsächlich in Bulgarien und Deutschland lebte und arbeitete. Er ist bekannt für seine Instrumente, da sie in der Musikwelt hoch geschätzt werden, da sie von den World Wide Violin Associations in den USA, Deutschland, Polen, Japan und der tschechischen Republik als eines der besten Instrumente der Welt anerkannt wurden 20. Jahrhundert. Dies ist die Familie, die sich um Maestro Schnellbacher gekümmert hat.`,
            // english: `Naiden Gantscheff (Gantchev) / 1918 - 1987 / is an Austrian-Slavic Violin maker who lived and worked primarily in Bulgaria and Germany. He's known for his instruments as they become highly appreciated among the music world given the recognition for tone richness and great quality by the World Wide Violin Associations in the USA, GERMANY, POLAND, JAPAN, CZECH republic, as one of the best instruments of the 20th Century. This is the family that took care of Maestro Schnellbacher.`,
            german: `DE`,
            english: <>Nayden Gantscheff is an Austrian violin maker whose instruments have won many awards in competitions in the USA, GERMANY, POLAND, JAPAN and Czechia. This is the family that took care of Maestro Schnellbacher.</>,
            spanish: `ES`,
            // spanish: `Naiden Gantscheff (Gantchev) / 1918 - 1987 / es un fabricante de violín austriaco-eslavo que vivió y trabajó principalmente en Bulgaria y Alemania. Es conocido por sus instrumentos, ya que se vuelven muy apreciados en el mundo de la música, dado el reconocimiento de la riqueza de tonos y la gran calidad por parte de las Asociaciones mundiales de violín en los Estados Unidos, Alemania, Polonia, Japón, República Checa, como uno de los mejores instrumentos de la siglo 20. Esta es la familia que se hizo cargo del maestro Schnellbacher.`,
        },
        {
            include: true,
            path: `75d85f84-31ae-4b6a-bea5-c05974f0e4ef.jpg`,
            name: `Bilingual English school 1985.jpg`,
            // german: `Maestro Schnellbacher singt in einem Kinderchor an der Bilingual English School, European Union.`,
            german: `DE`,
            english: <>Maestro Schnellbacher singing in a children choir at the Billingual English School, Rüthen, Germany, European Union.</>,
            spanish: `ES`,
            // spanish: `Maestro Schnellbacher cantando en un coro de niños en la Escuela Bilingüe de Inglés, Unión Europea.`,
        },
        {
            include: true,
            path: `8ad3c30e-f995-4154-bef4-85c3f402980e.jpg`,
            name: `Bilingual English school Concert 1985.jpg`,
            // german: `Maestro Schnellbacher spielt Geige an der Bilingual English School, Europäische Union.`,
            german: `DE`,
            english: <>Maestro Schnellbacher playing the violin at the Billingual English School, Rüthen, Germany, European Union.</>,
            spanish: `ES`,
            // spanish: `Maestro Schnellbacher tocando el violín en la Escuela Bilingüe de Inglés, Unión Europea.`,
        },
        {
            include: false,
            path: `7102afc4-a494-4077-bba8-b33c5f89c035.jpg`,
            name: `Bilingual English school, concert,1985.jpg`,
            // german: `Maestro Schnellbacher spielt Geige an der Bilingual English School, Europäische Union.`,
            german: `DE`,
            english: <>Maestro Schnellbacher playing the violin at the Billingual English School, European Union.</>,
            spanish: `ES`,
            // spanish: `Maestro Schnellbacher tocando el violín en la Escuela Bilingüe de Inglés, Unión Europea.`,
        },
        {
            include: false,
            path: `d3ea89f3-55bb-4d79-8a33-f1cb68b884d7.jpg`,
            name: `Berlin, Germany 1993.jpg`,
            // german: `Maestro Schnellbacher in Berlin, Deutschland, Europäische Union, 1993.`,
            german: `DE`,
            english: <>Maestro Schnellbacher in Berlin, Germany, European Union.</>,
            // english: `Maestro Schnellbacher in Berlin, Germany, European Union, 1993.`,
            spanish: `ES`,
            // spanish: `Maestro Schnellbacher en Berlín, Alemania, Unión Europea, 1993.`,
        },
    ],
}


export const contact = {
    title: {
        german: `DE`,
        english: <>Contact</>,
        spanish: `ES`,
    },
    form: {
        name: {
            label: {
                german: `DE`,
                english: `Name`,
                spanish: `ES`,
            },
            placeholder: {
                german: `DE`,
                english: `John Smith`,
                spanish: `ES`,
            },
        },
        email: {
            label: {
                german: `DE`,
                english: `Email`,
                spanish: `ES`,
            },
            placeholder: {
                german: `DE`,
                english: `john.smith123@gmail.com`,
                spanish: `ES`,
            },
        },
        message: {
            label: {
                german: `DE`,
                english: <>Message</>,
                spanish: `ES`,
            },
            placeholder: {
                german: `DE`,
                english: `Type your message here...`,
                spanish: `ES`,
            },
        },
    },
}


export const footer = {
    copyright: {
        german: `DE`,
        english: <>Walter von Schnellbacher</>,
        spanish: `ES`,
    },
}
