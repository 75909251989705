import { useSelector } from 'react-redux'

import { about } from 'content'


const About = _ => {
    const { language } = useSelector(({ translator }) => translator)
    
    return (
        <section id="about">
            <h2>{ about.title[ language ] }</h2>
            { about.paragraphs.map((paragraph, index) => <p key={ `lesson__paragraphs__${ index }` }>{ paragraph[ language ] }</p>) }
        </section>
    )
}


export default About
