import { HashLink } from 'react-router-hash-link'
import { useSelector } from 'react-redux'

import { experience } from 'content'


const Lesson = _ => {
    const { language } = useSelector(({ translator }) => translator)

    return (
        <section id="experience">
            <h2>{ experience.title[ language ] }</h2>
            { experience.paragraphs.map((paragraph, index) => <p key={ `lesson__paragraphs__${ index }` }>{ paragraph[ language ] }</p>) }
            <HashLink to="#contact" smooth>Contact</HashLink>
            {
                experience.articles.map((article, index) => (
                    <figure key={ `gallery__figure__${ article.path }` }>
                        <img src={ `/file/image/repair/${ article.path }` } width="450px" alt={ article[ language ] }/>
                        {/*<figcaption>{ article[ language ] }</figcaption>*/}
                    </figure>
                ))
            }
        </section>
    )
}


export default Lesson
