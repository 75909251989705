import axios from 'axios'


const api = `${ process.env.REACT_APP__SERVER_URL }/api`


const request = method => (path, { query, payload, config = { }, resolve, reject, sort }) => {
    const url = `${ api }/${ path }`
    console.log(`${ method.toUpperCase() } ${ url }`, query, payload)
    axios(Object.assign(config, { url, method }))
    axios({ ...config, url, method, data: payload })
        .then(response => {
            console.log(`RESPONSE:`, response)
            if (resolve === undefined) {
                return
            }
            resolve({ url, query, payload, response, data: response.data })
        })
        .catch(error => {
            console.log(`ERROR:`, error)
            alert(`error: ${ error }`)
            if (reject === undefined) {
                return
            }
            reject({url, query, payload, message: error.message, error })
        })
}


const requester = { }


Array
    .from([ `post`, `get`, `put`, `delete` ])
    .forEach(method => requester[ method ] = request(method))


export default requester
